import FadeInSection from '../../fadeinsection/Fadeinselection';
import FadeInSectionLeft from '../../fadeinsection/Fadeinsectionleft';
import FadeInSectionRight from '../../fadeinsection/Fadeinsectionright';
import './about-me.css';
import RandomNumberCode from './set-random-number';


// Для описания hard skills
interface ItemSkill {
	id: number;
	srcimgskill: string;
	skillsDescription: string;
	skillsClassNameReverse: string;
	fadein: string;
	fadeout: string;
}

interface previewMyWorksInte {
	id: number;
	nameWork: string;
	urlWork: string;
	imgWork: string;
}

const meSkilssItems: ItemSkill[] = [
	{id:1, fadein:"<FadeInSectionLeft key={ element.id }>", fadeout:"</FadeInSectionLeft>", srcimgskill:"../../../../assets/about-me/frontend-development.webp", skillsDescription:'Фронтенд-разработка: Я создаю современные и отзывчивые пользовательские интерфейсы с использованием Node JS, React и TypeScript. Мои проекты выделяются высококачественным дизайном и безупречным пользовательским опытом.', skillsClassNameReverse:''},
	{id:2, fadein:"<FadeInSectionLeft key={ element.id }>", fadeout:"</FadeInSectionLeft>", srcimgskill:"../../../../assets/about-me/backend-development.webp", skillsDescription:'Бекенд-разработка: В моем арсенале также мощные серверные технологии. Я проектирую и внедряю серверные приложения и API с использованием PHP и SQL, обеспечивая стабильность и безопасность данных.', skillsClassNameReverse:'reverse'},
	{id:3, fadein:"<FadeInSectionLeft key={ element.id }>", fadeout:"</FadeInSectionLeft>", srcimgskill:"../../../../assets/about-me/integration-deployment.webp", skillsDescription:'Интеграция и развертывание: Я обеспечиваю полную интеграцию фронтенд и бекенд решений, развертывая их на собственном сервере, чтобы обеспечить высокую производительность и надежность.', skillsClassNameReverse:''},
	{id:4, fadein:"<FadeInSectionLeft key={ element.id }>", fadeout:"</FadeInSectionLeft>", srcimgskill:"../../../../assets/about-me/backend-development.webp", skillsDescription:'Портфолио: Ознакомьтесь с моими предыдущими проектами, которые демонстрируют мою способность превращать идеи в функциональные и эстетически приятные веб-приложения.', skillsClassNameReverse:'reverse'},
	{id:5, fadein:"<FadeInSectionLeft key={ element.id }>", fadeout:"</FadeInSectionLeft>", srcimgskill:"../../../../assets/about-me/backend-development.webp", skillsDescription:'Мое хобби: в свободное время я занимаюсь 3D моделированием в программе "Blender". Мне нравиться процесс созднания чего-то нового, возможность воплащать свои идеи в виде цифрового искусства. Ознакомиться с моими 3D работами вы можете ознакомиться на сайте khlystikam.artstation.com', skillsClassNameReverse:''},
];


// Для социальных иконок в footer
interface ItemSocials {
	id: number;
	socialUrl: string;
	socialImg: string;
	socialName: string;
}

const contactWithMe: ItemSocials[] = [
	{id:1, socialUrl:"https://github.com/Khlystikam",socialImg:"https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png",socialName:"github"},
	{id:2, socialUrl:"https://t.me/dev_khlystikam",socialImg:"https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/512px-Telegram_logo.svg.png",socialName:"telegram"},
	{id:3, socialUrl:"https://khlystikam.artstation.com",socialImg:"https://cdn-bgp.bluestacks.com/BGP/us/gametiles_com.ballistiq.artstation.jpg",socialName:"artstation"},
]

const previewMyWorks: previewMyWorksInte[] = [
	{id:1, nameWork:"Веб объявления", urlWork:"/web-aplications/web-notise", imgWork:"../../../../assets/web-aplications/web-notise.jpg"},
	{id:2, nameWork:"Сайт номер 1", urlWork:"/web-aplications/web-notise", imgWork:"https://cdna.artstation.com/p/assets/images/images/071/734/808/large/aleksander-khlystik-1000004621.jpg?1705840898"},
	{id:3, nameWork:"Веб объявления", urlWork:"/web-aplications/web-notise", imgWork:"../../../../assets/web-aplications/OIG4.jpg"},
]


let item:boolean = true;

const AboutMe = () => {
	const transformedArray = meSkilssItems.map(element => {
		
		if (item === true) {
			item = false;

			return (
				<FadeInSectionRight>
					<div className={`my-skills-description_block ${element.skillsClassNameReverse}`}>
						<div className="my-skills-img_block">
							<img className="my-skills-img" src={ element.srcimgskill } alt="" />
						</div>
						<p className="my-skills-description">{ element.skillsDescription }</p>
					</div>
				</FadeInSectionRight>
			);
		} else {
			item = true;

			return (
				<FadeInSectionLeft>
					<div className={`my-skills-description_block ${element.skillsClassNameReverse}`}>
						<div className="my-skills-img_block">
							<img className="my-skills-img" src={ element.srcimgskill } alt="" />
						</div>
						<p className="my-skills-description">{ element.skillsDescription }</p>
					</div>
				</FadeInSectionLeft>
			);
		}
	});


	const contactWithMeArray = contactWithMe.map(element => {
    return (
			<a href={ element.socialUrl } className="link-social" key={ element.id } target="_blank" rel="noreferrer">
				<div className="social-icon">
					<img src={ element.socialImg } alt="" className="social-icon-img" />
				</div>
				<p className="social-name">{ element.socialName }</p>
			</a>
		);
	});

	return (
		<div className="about-me">
			<FadeInSection>
				<div className="greetings">
					<div className="about-expression-box">
						<h1 className="about-expression">«Из малых частиц создаются шедевры!»<br></br><br></br>Мои частицы будущих шедевров можете посмотреть на этом сайте.</h1>
						<video
							src="./assets/about-me/pebbles.webm"
							muted
							autoPlay
							loop
						/>
					</div>
					<div className="about-expression-img">
						<div className="blink-effect"></div>
						<a href={ previewMyWorks[0].urlWork }>
							<img className="image" src={ previewMyWorks[2].imgWork } alt="Wooden chest" />
						</a>
					</div>
				</div>
			</FadeInSection>

			<FadeInSection>
				<h2 className="welcom">Добро пожаловать на мой сайт!</h2>
			</FadeInSection>
			<FadeInSection>
				<div className="about-me-description">
					<p className="why-me">Кто я?</p>
					<p className="why-me-description">Я — веб-разработчик с хорошими знаниями в JavaScript, React и TypeScript. Моя специализация охватывает весь спектр веб-разработки, от создания интуитивно понятных интерфейсов до разработки несложных бэкэнд-систем на PHP. Я использую собственный сервер на «Raspberry PI» и развернутым на нем Apache-сервер с PHP и SQL, чтобы поддерживать надежные и масштабируемые решения для своих PET-проетов.</p>
					<div className="video-conveyor">
						<video
							src={`${process.env.PUBLIC_URL}/assets/about-me/ver.0.5.webm`}
							muted
							autoPlay
							loop 
						/>
					</div>
				</div>
			</FadeInSection>

			<FadeInSection>
				<div className="my-philosophy">
					<p className="my-philosophy-name">Моя философия!</p>
					<p className="my-philosophy-description">Превращать каждую идею в уникальный цифровой код, используя лучшие практики и современные технологии для создания веб-шедевров!</p>
					<div className="video-conveyor-lowpoly">
						<video
							src={`${process.env.PUBLIC_URL}/assets/about-me/LowPoly_ver_0.1.webm`}
							muted
							autoPlay
							loop 
						/>
					</div>
					<div className="my-philosophy-random-code">
						<RandomNumberCode/>
					</div>
				</div>
			</FadeInSection>

			<FadeInSection>
				<div className="my-skills">
					<p className="my-skills-name_block">Мои Хард-скиллы</p>
					{ transformedArray }
				</div>
			</FadeInSection>

			<FadeInSection>
				<div className="contact-with-me">
					{ contactWithMeArray }
				</div>
			</FadeInSection>
		</div>
	);
};

export default AboutMe;